<template>
  <div>
    <VTitle :title="$t('app.preview')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <Questionnaire
      v-else
      :id="1"
      class="-mt-10"
      :questions="questions"
      :data="evaluation"
      is-view
      display-correct-answers
    />
  </div>
</template>

<script>
import { computed, provide, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import VTitle from "@/components/VTitle";
import Questionnaire from "@/views/learning/evaluations/Questionnaire";

export default {
  components: {
    Questionnaire,
    VTitle
  },
  props: {
    // evaluation id
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Provide
    provide("evaluationType", "observations");

    // Composables
    const { getText } = useTexts();
    const { data, isLoading, read } = useReadMultiple();

    // Computed
    const questions = computed(() => {
      return data.value?.map(question => ({
        question
      }));
    });

    const evaluation = computed(() => {
      return {
        registration_evaluation: {
          evaluation: props.resource
        }
      };
    });

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.preview")} - ${t("app.quizzes", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.questions",
        pathParams: {
          id: props.id
        },
        queryParams: {
          ro_r: ["texts", "answers", "criteria", "image"]
        }
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      questions,
      evaluation,
      documentTitle,
      // useReadMultiple
      isLoading,
      // useTexts
      getText
    };
  }
};
</script>
